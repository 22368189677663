import { ePaymentMethodType } from '..';
import { IAddressDto } from './IAddressDto';


export interface IPaymentMethodDto {
    paymentMethodId: string;
    maskedAccountNumber: string;
    cardHolderName: string;
    type: ePaymentMethodType;
    dateLastUsed: Date;
    expirationDate: Date;
    dateCreated: Date;
    address: IAddressDto;
}
