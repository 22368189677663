<form [formGroup]="demographicsForm" class="needs-validation demographics-form" novalidate>

  <h5 class="ng4h-bg-secondary ng4h-panel-header">About You</h5>
  <div class="ng4h-panel-body">
    <div class="form-group full-width" *ng4hProgramSettings="{gender_Birth_On: true}">
      <label class="ng4h-required form-control-label">
        <ng-container *ng4hProgramSettings="{gender_Identity_On: true}">Birth</ng-container> Gender
      </label>

      <select class="form-control" formControlName="birthGender">
        <option [ngValue]="null"></option>
        <option [value]="option.key" *ngFor="let option of eGender | keyvalue: retainOrder">{{option.value |
          camelToTitleCase}}</option>
      </select>

    </div>
    <div class="form-group full-width" *ng4hProgramSettings="{gender_Identity_On: true}">
      <label class="form-control-label ng4h-required">
        <ng-container *ng4hProgramSettings="{gender_Birth_On: true}">
          Identifying</ng-container> Gender
      </label>

      <select class="form-control" formControlName="identifyingGender">
        <option [ngValue]="null"></option>
        <option [value]="option.key" *ngFor="let option of eGenderIdentity | keyvalue: retainOrder">{{option.value |
          genderAlias$ | async}}</option>
      </select>
    </div>


    <div class="form-group full-width">
      <label class="form-control-label ng4h-required">Grade</label>

      <select class="form-control" formControlName="schoolGrade">
        <option [ngValue]="null"></option>
        <option [value]="option.key" *ngFor="let option of eSchoolGradeType | keyvalue: retainOrder">{{option.value |
          camelToTitleCase | replace:'_':''}}</option>
      </select>
    </div>
  </div>

  <h5 class="ng4h-bg-secondary ng4h-panel-header">Demographics</h5>
  <div class="ng4h-panel-body">
    <div class="form-group full-width">
      <label class="form-control-label ng4h-required">Residence</label>

      <select class="form-control" formControlName="residence">
        <option [ngValue]="null"></option>
        <option value="Farm">Farm</option>
        <option value="TownUnder10000RuralNonFarm">Town Under 10,000 or Rural - Non Farm</option>
        <option value="TownCity10KTo50KAndSuburbs">Town, City or Suburbs 10,000 To 50,000</option>
        <option value="CityMoreThan50KSuburb">City or Suburb More Than 50,000</option>
        <option value="CityMoreThan50KCentral">City - Central, More Than 50,000</option>
      </select>
    </div>

    <div class="form-group full-width">
      <label class="form-control-label ng4h-required">Are you of Hispanic or Latino ethnicity?</label>

      <select class="form-control" formControlName="ethnicity">
        <option [ngValue]="null"></option>
        <option [value]="option.key" *ngFor="let option of eEthnicity | keyvalue">{{option.value}}</option>
      </select>
    </div>




    <fieldset>
      <legend [ngClass]="{'ng4h-required': demographicsForm.controls.ethnicity.value !== 'Hispanic'}">Race
      </legend>


      <div class="ng4h-border ng4h-padding ng4h-border-radius"
        [ngClass]="{'ng-invalid': demographicsForm.controls.ethnicity.value !== 'Hispanic' && demographicsForm.errors && demographicsForm.errors.noRaceSelected}">

        <div class="row">
          <div class="form-check">
            <mat-checkbox formControlName="racialBreakdownAmericanIndianOrAlaskanNative">American Indian or Alaskan
              Native</mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="form-check">
            <mat-checkbox formControlName="racialBreakdownAsian">Asian</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="form-check">
            <mat-checkbox formControlName="racialBreakdownBlack">Black or African American</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="form-check">
            <mat-checkbox formControlName="racialBreakdownNativeHawaiianOrPacificIslander">Native Hawaiian or Pacific
              Islander</mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="form-check">
            <mat-checkbox formControlName="racialBreakdownBalanceOfOtherCombinations">Other (race not listed)
            </mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="form-check">
            <mat-checkbox formControlName="racialBreakdownWhite">White</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="form-check">
            <mat-checkbox formControlName="racialBreakdownUndetermined">Prefer Not to State</mat-checkbox>
          </div>
        </div>
      </div>
    </fieldset>

  </div>
  <div *ng4hProgramSettings="{member_Show_Address: true}" formGroupName="address">
    <h5 class="ng4h-bg-secondary ng4h-panel-header">Address</h5>

    <div class="ng4h-panel-body">
      <div class="form-group full-width">
        <label class="form-control-label">Address</label>
        <input formControlName="addressLine1" class="form-control" type="text">
      </div>
      <div class="form-group full-width">
        <label class="form-control-label">Address 2</label>
        <input formControlName="addressLine2" class="form-control" type="text">
      </div>
      <div class="form-group full-width">
        <label class="form-control-label">City</label>
        <input formControlName="city" class="form-control" type="email">
      </div>
      <div class="form-group full-width">
        <label class="form-control-label">State</label>
        <!-- <input formControlName="state" class="form-control" type="text"> -->
        <ng4h-state-dropdown formControlName="state"></ng4h-state-dropdown>
      </div>
      <div class="form-group full-width">
        <label class="form-control-label">Postal Code</label>
        <input formControlName="postalCode" class="form-control" type="text">
      </div>

    </div>
  </div>

  <div formGroupName="emergencyContact">

    <h5 class="ng4h-bg-secondary ng4h-panel-header">Emergency Contact</h5>

    <div class="ng4h-panel-body">
      <div class="form-group full-width">
        <label class="ng4h-required form-control-label">Full Name</label>

        <input formControlName="emergencyContact" type="text" class="form-control">

      </div>

      <div class="form-group full-width">
        <label class="ng4h-required form-control-label">Relationship to member</label>

        <input formControlName="emergencyContactRelationship" type="text" class="form-control">

      </div>

      <div class="form-group full-width">
        <label class="ng4h-required form-control-label">Contact Phone</label>

        <input formControlName="emergencyContactPhone" type="text" class="form-control" [maxLength]="12" type="tel"
          ng4hPhoneMask>

      </div>

      <div class="form-group full-width">
        <label class="form-control-label">Contact Email</label>

        <input formControlName="emergencyContactEmail" type="email" class="form-control" email>

      </div>
    </div>


    <ng-container *ng4hProgramSettings="{member_Show_Second_EmergencyContact: true}">

      <h5 class="ng4h-bg-secondary ng4h-panel-header">Emergency Contact 2</h5>

      <div class="ng4h-panel-body">
        <div class="form-group full-width">
          <label class="form-control-label">Full Name</label>

          <input formControlName="emergencyContact2" type="text" class="form-control">

        </div>

        <div class="form-group full-width">
          <label class="form-control-label">Relationship to member</label>

          <input formControlName="emergencyContact2Relationship" type="text" class="form-control">

        </div>

        <div class="form-group full-width">
          <label class="form-control-label">Contact Phone</label>

          <input formControlName="emergencyContact2Phone" type="text" class="form-control" [maxLength]="12" type="tel"
            ng4hPhoneMask>

        </div>

        <div class="form-group full-width">
          <label class="form-control-label">Contact Email</label>

          <input formControlName="emergencyContact2Email" type="email" class="form-control" email>

        </div>
      </div>


    </ng-container>
  </div>

  <div formGroupName="guardian">

    <h5 class="ng4h-bg-secondary ng4h-panel-header">Parent / Guardian 1</h5>

    <div class="ng4h-panel-body">
      <div class="form-group full-width">
        <label class="form-control-label ng4h-required">First Name</label>

        <input formControlName="guardian1FirstName" type="text" class="form-control">

      </div>

      <div class="form-group full-width">
        <label class="form-control-label ng4h-required">Last Name</label>

        <input formControlName="guardian1LastName" type="text" class="form-control">

      </div>

      <div class="form-group full-width">
        <label class="form-control-label ng4h-required">Phone Number</label>

        <input formControlName="guardian1Phone" type="tel" class="form-control" [maxLength]="12" type="tel"
          ng4hPhoneMask>

      </div>

      <div class="form-group full-width">
        <label class="form-control-label">Work Phone Number</label>

        <input formControlName="guardian1WorkPhone" type="tel" class="form-control" [maxLength]="12" type="tel"
          ng4hPhoneMask>

      </div>

      <div class="form-group full-width">
        <label class="form-control-label">Work Extension</label>

        <input formControlName="guardian1WorkExt" type="tel" class="form-control">

      </div>
    </div>

    <h5 class="ng4h-bg-secondary ng4h-panel-header">Parent / Guardian 2</h5>

    <div class="ng4h-panel-body">
      <div class="form-group full-width">
        <label class="form-control-label">First Name</label>

        <input formControlName="guardian2FirstName" type="text" class="form-control">

      </div>

      <div class="form-group full-width">
        <label class="form-control-label">Last Name</label>

        <input formControlName="guardian2LastName" type="text" class="form-control">

      </div>

      <div class="form-group full-width">
        <label class="form-control-label">Phone Number</label>

        <input formControlName="guardian2Phone" type="tel" class="form-control" [maxLength]="12" type="tel"
          ng4hPhoneMask>

      </div>

      <div class="form-group full-width">
        <label class="form-control-label">Work Phone Number</label>

        <input formControlName="guardian2WorkPhone" type="tel" class="form-control" [maxLength]="12" type="tel"
          ng4hPhoneMask>

      </div>

      <div class="form-group full-width">
        <label class="form-control-label">Work Extension</label>

        <input formControlName="guardian2WorkExt" type="tel" class="form-control">
      </div>

    </div>
  </div>

  <div *ng4hProgramSettings="{member_Show_Second_Family: true}" formGroupName="secondFamily">
    <h5 class="ng4h-bg-secondary ng4h-panel-header">Second Family</h5>

    <div class="ng4h-panel-body">
      <div class="form-group full-width">
        <label class="form-control-label">Name</label>
        <input formControlName="secondFamilyName" class="form-control" type="text">
      </div>
      <div class="form-group full-width">
        <label class="form-control-label">First Names</label>
        <input formControlName="secondFamilyFirstNames" class="form-control" type="text">
      </div>
      <div class="form-group full-width">
        <label class="form-control-label">Email</label>
        <input formControlName="secondFamilyEmail" class="form-control" type="text">
      </div>
      <div class="form-group full-width">
        <label class="form-control-label">Phone</label>
        <input formControlName="secondFamilyPhone" class="form-control" type="text">
      </div>

      <div class="form-group full-width">
        <label class="form-control-label">Address</label>
        <input formControlName="secondFamilyAddressLine1" class="form-control" type="text">
      </div>
      <div class="form-group full-width">
        <label class="form-control-label">Address 2</label>
        <input formControlName="secondFamilyAddressLine2" class="form-control" type="text">
      </div>
      <div class="form-group full-width">
        <label class="form-control-label">City</label>
        <input formControlName="secondFamilyCity" class="form-control" type="email">
      </div>
      <div class="form-group full-width">
        <label class="form-control-label">State</label>
        <!-- <input formControlName="state" class="form-control" type="text"> -->
        <ng4h-state-dropdown formControlName="secondFamilyState"></ng4h-state-dropdown>
      </div>
      <div class="form-group full-width">
        <label class="form-control-label">Postal Code</label>
        <input formControlName="secondFamilyPostalCode" class="form-control" type="text">
      </div>
    </div>

  </div>


  <h5 class="ng4h-bg-secondary ng4h-panel-header">School</h5>
  <div class="ng4h-panel-body">
    <div class="col-12 form-radio" (click)="schoolTrueClicked()">
      <input type="radio" formControlName="schoolInputSelectionRadio" [value]="true"
        [checked]="demographicsForm.controls['schoolInputSelectionRadio'].value === true">
      Please select your school from the list below, by selecting your school's county, then district and then your
      school
    </div>


    <div class="form-group full-width">
      <label class="form-control-label"
        [ngClass]="{'ng4h-required': demographicsForm.controls.schoolInputSelectionRadio.value}">School
        County</label>

      <ng4h-county-select [programId]="programId$ | async" formControlName="schoolCountyId"></ng4h-county-select>

    </div>

    <div class="form-group full-width">
      <label class="form-control-label"
        [ngClass]="{'ng4h-required': demographicsForm.controls.schoolInputSelectionRadio.value}">School
        District</label>

      <select class="form-control" formControlName="schoolDistrictId">
        <option [ngValue]="null"></option>
        <option [value]="district.id" *ngFor="let district of schoolDistricts$ | async">{{district.name}}</option>
      </select>


    </div>

    <div class="form-group full-width">
      <label class="form-control-label"
        [ngClass]="{'ng4h-required': demographicsForm.controls.schoolInputSelectionRadio.value}">School
        Name</label>

      <select class="form-control" formControlName="schoolId">
        <option [ngValue]="null"></option>
        <option [value]="school.schoolId" *ngFor="let school of schoolsByDistrict$ | async">{{school.schoolName}}
        </option>
      </select>

    </div>


    <div class="col-12 form-radio" (click)="schoolFalseClicked()">
      <input type="radio" formControlName="schoolInputSelectionRadio" [value]="false"
        [checked]="demographicsForm.controls['schoolInputSelectionRadio'].value === false">
      If you are unable to locate your school in the list above, please enter the school name and type, in the
      fields
      below
    </div>

    <div class="form-group full-width">
      <label class="form-control-label"
        [ngClass]="{'ng4h-required': !demographicsForm.controls.schoolInputSelectionRadio.value}">School
        Name</label>

      <input class="form-control" type="text" formControlName="schoolName">

    </div>

    <div class="form-group full-width">
      <label class="form-control-label"
        [ngClass]="{'ng4h-required': !demographicsForm.controls.schoolInputSelectionRadio.value}">School
        Type</label>

      <select class="form-control" formControlName="schoolType">
        <option [ngValue]="null"></option>
        <option [value]="option.key" *ngFor="let option of eSchoolType | keyvalue">{{option.value |
          camelToTitleCase}}</option>
      </select>

    </div>
  </div>

  <h5 class="ng4h-bg-secondary ng4h-panel-header">Military</h5>

  <div class="ng4h-panel-body">
    <div class="form-group full-width">
      <label class="form-control-label ng4h-required">Family Member Military Service</label>

      <select class="form-control" formControlName="militaryService">
        <option [ngValue]="null"></option>
        <option [value]="eMilitaryFamilyServiceType.IAmServingInTheMilitary">
          {{eMilitaryFamilyServiceType.IAmServingInTheMilitary | camelToTitleCase}}</option>
        <option [value]="eMilitaryFamilyServiceType.IHaveAFamilyMemberServingInTheMilitary">
          {{eMilitaryFamilyServiceType.IHaveAFamilyMemberServingInTheMilitary | camelToTitleCase}}</option>
        <option [value]="eMilitaryFamilyServiceType.IHaveAFamilyMemberWhoRetiredFromTheMilitary">
          {{eMilitaryFamilyServiceType.IHaveAFamilyMemberWhoRetiredFromTheMilitary | camelToTitleCase}}</option>
        <option [value]="eMilitaryFamilyServiceType.IHaveAParentServingInTheMilitary">
          {{eMilitaryFamilyServiceType.IHaveAParentServingInTheMilitary | camelToTitleCase}}</option>
        <option [value]="eMilitaryFamilyServiceType.IHaveASiblingServingInTheMilitary">
          {{eMilitaryFamilyServiceType.IHaveASiblingServingInTheMilitary | camelToTitleCase}}</option>
        <option [value]="eMilitaryFamilyServiceType.IHaveAParentWhoServedInTheMilitary">
          {{eMilitaryFamilyServiceType.IHaveAParentWhoServedInTheMilitary | camelToTitleCase}}</option>
        <option [value]="eMilitaryFamilyServiceType.IHaveAParentWhoRetiredFromTheMilitary">
          {{eMilitaryFamilyServiceType.IHaveAParentWhoRetiredFromTheMilitary | camelToTitleCase}}</option>
        <option [value]="eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary">
          {{eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary | camelToTitleCase}}</option>
      </select>

    </div>


    <div class="form-group full-width">
      <label class="form-control-label"
        [ngClass]="{'ng4h-required': demographicsForm.controls.militaryService.value != eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary}">Branch
        of Service</label>

      <select class="form-control" formControlName="militaryBranch">
        <option [ngValue]="null"></option>
        <option [value]="option.key" *ngFor="let option of eMilitaryBranchType | keyvalue">{{option.value |
          camelToTitleCase}}</option>
      </select>

    </div>

    <div class="form-group full-width">
      <label class="form-control-label"
        [ngClass]="{'ng4h-required': demographicsForm.controls.militaryService.value != eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary}">Branch
        Component</label>

      <select class="form-control" formControlName="militaryComponent">
        <option [ngValue]="null"></option>
        <option [value]="option.key" *ngFor="let option of eMilitaryComponentType | keyvalue">{{option.value |
          camelToTitleCase}}</option>
      </select>
    </div>
  </div>

</form>