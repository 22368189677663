<div class="modal-section">
    <div class="modal-header">
      <h5 class="modal-title">Credit Card</h5>
      <button (click)="cancel()" type="button" class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="ng4h-panel-body ng4h-table">
        <div class="col-12">
          <div class="row">
            <div class="col-4 ng4h-bg-secondary"><b>Card Holder Name</b></div>
            <div class="col-8">{{ paymentMethod.cardHolderName }}</div>
          </div>
          <div class="row">
            <div class="col-4 ng4h-bg-secondary"><b>Type</b></div>
            <div class="col-8">{{ paymentMethod.type }}</div>
          </div>
          <div class="row">
            <div class="col-4 ng4h-bg-secondary"><b>Expiration Date</b></div>
            <div class="col-8">{{paymentMethod.expirationDate}}</div>
          </div>
          <div class="row">
            <div class="col-4 ng4h-bg-secondary"><b>Account Number</b></div>
            <div class="col-8">{{paymentMethod.maskedAccountNumber}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button id (click)="cancel()" class="btn btn-secondary my-2 my-sm-0" role="button"
        aria-pressed="true">Cancel</button>
      <button (click)="delete()" class="btn btn-danger my-2 my-sm-0" role="button"
        aria-pressed="true">Delete</button>
    </div>
  </div>
