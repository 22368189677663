<form [formGroup]="demographicsForm" class="needs-validation demographics-form" novalidate>

  <h5 class="ng4h-bg-secondary ng4h-panel-header">About You</h5>
  <div class="ng4h-panel-body">
    <div class="form-group full-width" *ng4hProgramSettings="{gender_Birth_On: true}">
      <label class="ng4h-required form-control-label">
        <ng-container *ng4hProgramSettings="{gender_Identity_On: true}">Birth</ng-container> Gender
      </label>
      <div class="form-select ">
        <select class="form-control" formControlName="birthGender">
          <option [ngValue]="null"></option>
          <option [value]="option.key" *ngFor="let option of eGender | keyvalue: retainOrder">{{option.value |
            camelToTitleCase}}</option>
        </select>
      </div>

    </div>
    <div class="form-group full-width" *ng4hProgramSettings="{gender_Identity_On: true}">
      <label class="form-control-label ng4h-required">
        <ng-container *ng4hProgramSettings="{gender_Birth_On: true}">
          Identifying</ng-container> Gender
      </label>

      <div class="form-select ">
        <select class="form-control" formControlName="identifyingGender">
          <option [ngValue]="null"></option>
          <option [value]="option.key" *ngFor="let option of eGenderIdentity | keyvalue: retainOrder">{{option.value |
            genderAlias$ | async}}</option>
        </select>
      </div>
    </div>

    <div class="form-group full-width">
      <label class="form-control-label">Job Title</label>
      <input formControlName="jobTitle" class="form-control" type="text">
    </div>
    <div class="form-group full-width">
      <label class="form-control-label">Employer</label>
      <input formControlName="employer" class="form-control" type="text">
    </div>
    <div class="form-group full-width">
      <label class="form-control-label">Work Phone</label>
      <input formControlName="workPhone" class="form-control" type="text">
    </div>
    <div class="form-group full-width">
      <label class="form-control-label">Work Extension</label>
      <input formControlName="workExt" class="form-control" type="text">
    </div>
  </div>

  <h5 class="ng4h-bg-secondary ng4h-panel-header">Demographics</h5>
  <div class="ng4h-panel-body">
    <div class="form-group full-width">

      <div class="form-group full-width">
        <label class="form-control-label ng4h-required">Residence</label>

        <div class="form-select">
          <select class="form-control" formControlName="residence">
            <option [ngValue]="null"></option>
            <option value="Farm">Farm</option>
            <option value="TownUnder10000RuralNonFarm">Town Under 10,000 or Rural - Non Farm</option>
            <option value="TownCity10KTo50KAndSuburbs">Town, City or Suburbs 10,000 To 50,000</option>
            <option value="CityMoreThan50KSuburb">City or Suburb More Than 50,000</option>
            <option value="CityMoreThan50KCentral">City - Central, More Than 50,000</option>
          </select>
        </div>
      </div>

      <label class="form-control-label ng4h-required">Are you of Hispanic or Latino ethnicity?</label>

      <div class="form-select">
        <select class="form-control" formControlName="ethnicity">
          <option [ngValue]="null"></option>
          <option [value]="option.key" *ngFor="let option of eEthnicity | keyvalue">{{option.value}}</option>
        </select>
      </div>
    </div>

    <fieldset>
      <legend [ngClass]="{'ng4h-required': demographicsForm.controls.ethnicity.value !== 'Hispanic'}">Race
      </legend>
      <div class="ng4h-border ng4h-padding ng4h-border-radius"
        [ngClass]="{'ng-invalid': demographicsForm.controls.ethnicity.value !== 'Hispanic' && demographicsForm.errors && demographicsForm.errors.noRaceSelected}">

        <div class="row">
          <div class="form-check">
            <mat-checkbox formControlName="racialBreakdownAmericanIndianOrAlaskanNative">American Indian or Alaskan
              Native</mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="form-check">
            <mat-checkbox formControlName="racialBreakdownAsian">Asian</mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="form-check">
            <mat-checkbox formControlName="racialBreakdownBlack">Black or African American</mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="form-check">
            <mat-checkbox formControlName="racialBreakdownNativeHawaiianOrPacificIslander">Native Hawaiian or Pacific
              Islander</mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="form-check">
            <mat-checkbox formControlName="racialBreakdownBalanceOfOtherCombinations">Other (race not listed)
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="form-check">
            <mat-checkbox formControlName="racialBreakdownWhite">White</mat-checkbox>
          </div>
        </div>

        <div class="row">
          <div class="form-check">
            <mat-checkbox formControlName="racialBreakdownUndetermined">Prefer Not to State</mat-checkbox>
          </div>
        </div>
      </div>
    </fieldset>
  </div>

  <div formGroupName="emergencyContact">

    <h5 class="ng4h-bg-secondary ng4h-panel-header">Emergency Contact</h5>
    <div class="ng4h-panel-body">
      <div class="form-group full-width">
        <label class="ng4h-required form-control-label">Full Name</label>
        <div class="form-input">
          <input formControlName="emergencyContact" type="text" class="form-control">
        </div>
      </div>

      <div class="form-group full-width">
        <label class="ng4h-required form-control-label">Relationship to member</label>
        <div class="form-input">
          <input formControlName="emergencyContactRelationship" type="text" class="form-control">
        </div>
      </div>

      <div class="form-group full-width">
        <label class="ng4h-required form-control-label">Contact Phone</label>
        <div class="form-input">
          <input formControlName="emergencyContactPhone" type="text" class="form-control" [maxLength]="12" type="tel"
            ng4hPhoneMask>
        </div>
      </div>

      <div class="form-group full-width">
        <label class="form-control-label">Contact Email</label>
        <div class="form-input">
          <input formControlName="emergencyContactEmail" type="email" class="form-control" email>
        </div>
      </div>
    </div>


    <ng-container *ng4hProgramSettings="{member_Show_Second_EmergencyContact: true}">

      <h5 class="ng4h-bg-secondary ng4h-panel-header">Emergency Contact 2</h5>

      <div class="ng4h-panel-body">
        <div class="form-group full-width">
          <label class="form-control-label">Full Name</label>

          <input formControlName="emergencyContact2" type="text" class="form-control">

        </div>

        <div class="form-group full-width">
          <label class="form-control-label">Relationship to member</label>

          <input formControlName="emergencyContact2Relationship" type="text" class="form-control">

        </div>

        <div class="form-group full-width">
          <label class="form-control-label">Contact Phone</label>

          <input formControlName="emergencyContact2Phone" type="text" class="form-control" [maxLength]="12" type="tel"
            ng4hPhoneMask>

        </div>

        <div class="form-group full-width">
          <label class="form-control-label">Contact Email</label>

          <input formControlName="emergencyContact2Email" type="email" class="form-control" email>

        </div>
      </div>


    </ng-container>
  </div>

  <h5 class="ng4h-bg-secondary ng4h-panel-header">Military</h5>
  <div class="ng4h-panel-body">
    <div class="form-group full-width">
      <label class="form-control-label ng4h-required">Family Member Military Service</label>
      <div class="form-input">
        <select class="form-control" formControlName="militaryService">
          <option [ngValue]="null"></option>
          <option [value]="eMilitaryFamilyServiceType.IAmServingInTheMilitary">
            {{eMilitaryFamilyServiceType.IAmServingInTheMilitary | camelToTitleCase}}</option>
          <option [value]="eMilitaryFamilyServiceType.IHaveAFamilyMemberServingInTheMilitary">
            {{eMilitaryFamilyServiceType.IHaveAFamilyMemberServingInTheMilitary | camelToTitleCase}}</option>
          <option [value]="eMilitaryFamilyServiceType.IHaveRetiredFromTheMilitary">
            {{eMilitaryFamilyServiceType.IHaveRetiredFromTheMilitary | camelToTitleCase}}</option>
          <option [value]="eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary">
            {{eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary | camelToTitleCase}}</option>


        </select>
      </div>
    </div>


    <div class="form-group full-width">
      <label class="form-control-label"
        [ngClass]="{'ng4h-required': demographicsForm.controls.militaryService.value != eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary}">Branch
        of Service</label>
      <div class="form-input">
        <select class="form-control" formControlName="militaryBranch">
          <option [ngValue]="null"></option>
          <option [value]="option.key" *ngFor="let option of eMilitaryBranchType | keyvalue">{{option.value |
            camelToTitleCase}}</option>
        </select>
      </div>
    </div>

    <div class="form-group full-width">
      <label class="form-control-label"
        [ngClass]="{'ng4h-required': demographicsForm.controls.militaryService.value != eMilitaryFamilyServiceType.NoOneInMyFamilyIsServingInTheMilitary}">Branch
        Component</label>
      <div class="form-input">
        <select class="form-control" formControlName="militaryComponent">
          <option [ngValue]="null"></option>
          <option [value]="option.key" *ngFor="let option of eMilitaryComponentType | keyvalue">{{option.value |
            camelToTitleCase}}</option>
        </select>
      </div>
    </div>
  </div>

</form>