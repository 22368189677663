import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AutoUnsubscribe,
  eProfileType,
  IFamilyInstitutionProfile,
  IGatewayConfigurationDto,
  ITokenizedCardDto,
} from 'app/core/models';
import { CommonToastrService, CreditCardService, ProfileService, RouterService } from 'app/core/services';
import { filter, map, Observable, switchMap, take } from 'rxjs';

@Component({
  selector: 'ng4h-authorize-net-credit-card-input',
  templateUrl: './authorize-net-credit-card-input.component.html',
  styleUrls: ['./authorize-net-credit-card-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorizeNetCreditCardInputComponent extends AutoUnsubscribe implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('authorizeForm', { static: true }) authorizeForm;

  @Input() gatewayConfig: IGatewayConfigurationDto;

  @Output() tokenizationSuccess: EventEmitter<ITokenizedCardDto>;
  public ready$: Observable<boolean>;

  private familyId$: Observable<string>;
  public token: string;

  public loading = false;
  constructor(
    private profileService: ProfileService,
    private creditCardService: CreditCardService,
    private cdr: ChangeDetectorRef,
    private routerService: RouterService,
    private toastrService: CommonToastrService
  ) {
    super();

    this.tokenizationSuccess = new EventEmitter();

  }

  ngOnInit(): void {

    this.familyId$ = this.profileService.loggedInAsInstitutionProfile.pipe(
      filter(profile => profile.profileType === eProfileType.Family),
      take(1),
      map((profile: IFamilyInstitutionProfile) => profile.familyId)
    );

    window.addEventListener('message', this.cardAdded.bind(this), false);


  }

  ngAfterViewInit(): void {
    this.loading = true;
    this.familyId$.pipe(
      take(1),
      switchMap(familyId => {
        return this.creditCardService.getAuthorizeNetToken({
          institutionId: this.routerService.institutionId,
          familyId,
          programId: this.routerService.programId
        });
      }),
      take(1)
    ).subscribe({
      next: token => {
        this.token = token.token;
        this.cdr.detectChanges();
        this.authorizeForm.nativeElement.submit();
      }, error: (error) => {
        this.loading = false;
        this.toastrService.error('Could not fetch Authorize.net token', error);
      }
    });
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.cardAdded.bind(this), false);
  }

  public onLoad() {
    this.loading = false;
  }

  private cardAdded(event: MessageEvent) {
    if (event.data === 'action=successfulSave') {
      this.tokenizationSuccess.emit({} as any);
    }
  }

}
